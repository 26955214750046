// TODO automatic script which copies prisma enums?
// enum translations: look for "enum:CompanyAddressType_" to find usage examples

export enum NotificationType {
  default = "default",
}

export enum TradeProductState {
  Active = "Active",
  Deactivated = "Deactivated",
}

export enum CompanyAddressType {
  Loading = "Loading",
  Delivery = "Delivery",
  Billing = "Billing",
}

export enum TagType {
  String = "String",
  Number = "Number",
}

export enum UserTradeProductStatusType {
  Open = "Open",
  PartialAcceptance = "PartialAcceptance",
  FullAcceptance = "FullAcceptance",
  Closed = "Closed",
  Expired = "Expired",
}

export enum UserTradeProductDeliveryType {
  NotSet = "NotSet",
  Self = "Self",
  Connect8 = "Connect8",
}

export enum PriceHistoryCreatorType {
  Creator = "Creator",
  Bidder = "Bidder",
}

export enum LoginType {
  User = "User",
  InternalEmployee = "InternalEmployee",
  Company = "Company",
}

export enum TaskType {
  CompanyActivation = "CompanyActivation",
  JoinConnect8 = "JoinConnect8",
  Bug = "Bug",
  DevelopmentRequest = "DevelopmentRequest",
  GeneralRequest = "GeneralRequest",
  BillingIssue = "BillingIssue",
  PartnerRequest = "PartnerRequest",
  JobRequest = "JobRequest",
}

export enum InvoiceState {
  Open = "Open",
  Invoiced = "Invoiced",
  Payed = "Payed",
  Problem = "Problem",
}

export enum InvoicePositionType {
  Trade = "Trade",
  Delivery = "Delivery",
}

export enum InvoiceStepStatus {
  None = "None",
  StripeDone = "StripeDone",
  DMSUploadDone = "DMSUploadDone",
  AllDone = "AllDone",
}

export enum NewsType {
  Alert = "Alert",
  Default = "Default",
}

export enum EventType {
  Maintenance = "Maintenance",
  Stream = "Stream",
}
